export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },
  DASHBOARD: {
    FIRST_LINE_COUNT: "v1/admin/dashboard/first-line-counts",
    STUDENTS_BIRTHDAY_LIST: "v1/admin/dashboard/students-birthday-list",
    STAFFS_BIRTHDAY_LIST: "v1/admin/dashboard/staffs-birthday-list",

    STUDENTS_COUNT: "v1/admin/dashboard/students-count",
    TODAY_DAY_ORDER: "v1/admin/dashboard/today-day-order",
    TODAY_ATTENDANCE_COUNT: "v1/admin/dashboard/today-attendance-count",
  },
  ACADEMIC: {
    LIST_ACADEMIC_YEARS: "v1/admin/academic_years/list-academic-years",
    LIST_COURSES: "v1/admin/courses/list-courses",
    LIST_COURSES_V2: "v1/admin/courses/list-courses", // New Course List based on v2 Department
    REMOVE_COURSE_FROM_DEPT: "v1/admin/courses/remove-course-from-dept", // New Course List based on v2 Department

    SAVE_COURSES: "v1/admin/courses/save-course",
    UPDATE_COURSES: "v1/admin/courses/update-course",
    REMOVE_COURSES: "v1/admin/courses/remove-course",
  },
  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  ADMISSION: {
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    LIST_PAGES: "v1/admin/webpage/list-pages",
    UPDATE_PAGE: "v1/admin/webpage/update-page",
    VIEW_PAGE: "v1/admin/webpage/view-page",
    DELETE_PAGE: "v1/admin/webpage/delete-page",
    ADD_PAGE: "v1/admin/webpage/add-page",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
  },
  STUDENTS: {
    SEARCH_BY_REGISTER_NO: "v1/admin/student_search/search-student-by-no",
    SEARCH_BY_NAME: "v1/admin/student_search/search-by-name",
    INCOMPLETE_PROFILES: "v1/admin/student_search/incomplete",

    SAVE_BUS_ROUTE: "v1/admin/student/save-vehicle",
    TRANSPORT_DETAILS: "v1/admin/student_search/transport-details",
    PROMOTION_DETAILS: "v1/admin/student_search/promotion-details",
    SAVE_PROMOTION: "v1/admin/student/save-promotion",
    REMOVE_PROMOTION: "v1/admin/student/remove-promotion",
    UPDATE_BULK_FIELD: "v1/admin/student/update-bulk-field",

    SAVE_STUDENT: "v1/admin/student/save-student",
    UPDATE_STUDENT: "v1/admin/student/update-student",
    UPDATE_HOSTEL_DATES: "v1/admin/student/update-hostel-dates",

    STUDENT_STRENGTH: "v1/admin/student_report/current-strength",
    STUDENTS_PROFILE_INCOMPLETE_COUNT:
      "v1/admin/student_report/incomplete-count",
    STUDENTS_PROFILE_INCOMPLETE_LIST:
      "v1/admin/student_report/incomplete-students",
    INCOMPLETE_REPORT: "v1/admin/student_report/incomplete-report",

    IMPORT_STUDENT: "v1/admin/importstudent/save",
    BULK_PHOTO_UPLOAD: "v1/admin/student/bulk-photo-upload",
    UPDATE_TC_DATES: "v1/admin/student/tc-date-update",
    SAVE_STUDENT_TC: "v1/admin/student/save-tc",
    UPDATE_TRANSPORT_ID: "v1/admin/student/update-transport-id",

    SAVE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/save",
    LIST_BONAFIED_CERTIFICATES: "v1/admin/student/bonaifed/list-all",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
    SAVE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/save",
    LIST_CONDUCT_CERTIFICATES: "v1/admin/student/conduct/list-all",
    DELETE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/delete",
    SAVE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/save",
    LIST_MEDIUM_CERTIFICATES: "v1/admin/student/medium/list-all",
    DELETE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/delete",
    LIST_VERIFY_CERTIFICATE: "v1/admin/student/verification/list",
    SAVE_VERIFY_CERTIFICATE: "v1/admin/student/verification/save",

    SAVE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/save",
    LIST_COMPLETION_CERTIFICATES: "v1/admin/student/completion/list-all",
    DELETE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/delete",

    DATE_WISE_ATTENDANCE: "v1/admin/student/attendance/get",
    UPDATE_DAY_ATTENDANCE: "v1/admin/student/attendance/update",
    SAVE_LONG_ABSENT_STUDENT: "v1/admin/student/attendance/save-long-absent",
    LONG_ABSENT_STUDENTS: "v1/admin/student/attendance/long-absentee",
    GET_MARKED_GROUP_BY_DAYHOUR: "v1/admin/student/attendance/get-marked",
    DELETE_LONG_ABSENT_STUDENT:
      "v1/admin/student/attendance/delete-long-absentee",
    DAy_WISE_ENTRY_RECORDS: "v1/admin/student/attendance/get-day-records",
    DELETE_DAY_ENTRY: "v1/admin/student/attendance/delete-day-records",
    MARK_BULK_OD: "v1/admin/student/attendance/mark-bulkod",
    DAY_WISE_ATTENDANCE_COUNT: "v1/admin/student/attendance/day-wise-count",
    SAVE_SINGLE_OD_ENTRY: "v1/admin/student/attendance/single-od-update",

    ATTENDANCE_COUNT_REPORT: "v1/admin/student/attendance/reports",
    ATTENDANCE_MONTHLY_REPORT: "v1/admin/student/attendance/monthly-report",
    STUDENT_SMS_LOGS: "v1/admin/student/attendance/sms-logs",
    TODAY_FULL_ABSENT_COUNT: "v1/admin/student/attendance/full-absent-count",
    COURSE_LACK_REPORT: "v1/admin/student/attendance/course-lack-report",
    SEND_SMS_ABSENTES_MANUALLY:
      "v1/admin/student/attendance/send-sms-to-absentees",

    ALLOCATED_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocated-list",
    ALLOCATE_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocate",
    STUDENTS_SUBJECT_LIST: "v1/admin/student/subject/students-allocated-list",
    DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/delete-student-subject",
    FIND_COURSE_SUBJECT: "v1/admin/student/subject/find",
    UPDATE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/change-subject",
    SAVE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/add-subject",
    DELETE_ALLOCATED_SUBJECTS: "v1/admin/student/subject/delete-allocated",

    REMOVE_LEFT: "v1/admin/student/remove-left",
    UPDATE_LEFT: "v1/admin/student/update-left",

    SAVE_HOSTEL_ADMISSION: "v1/admin/hostel/admission/save",
    LIST_HOSTEL_STUDENTS: "v1/admin/hostel/admission/list",
    DELETE_HOSTEL_STUDENT: "v1/admin/hostel/admission/delete",
    UPDATE_HOSTEL_STUDENT: "v1/admin/hostel/admission/update",
    SAVE_BULK_HOSTEL_ADMISSION: "v1/admin/hostel/admission/bulk-save",
  },
  SETTINGS: {
    SAVE_CASHBOOKS: "v1/admin/cashbooks/save",
    LIST_CASHBOOKS: "v1/admin/cashbooks/list",
    DELETE_CASHBOOK: "v1/admin/cashbooks/delete",
    UPDATE_CASHBOOK: "v1/admin/cashbooks/update",

    SAVE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/save",
    LIST_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/list",
    DELETE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/delete",
    UPDATE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/update",

    SAVE_COLLEGE_YEAR: "v1/admin/collegeyear/save",
    LIST_COLLEGE_YEAR: "v1/admin/collegeyear/list",
    DELETE_COLLEGE_YEAR: "v1/admin/collegeyear/delete",
    UPDATE_COLLEGE_YEAR: "v1/admin/collegeyear/update",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    SAVE_BATCH: "v1/admin/settings/batch/save",
    LIST_BATCH: "v1/admin/settings/batch/list",
    DELETE_BATCH: "v1/admin/settings/batch/delete",
    UPDATE_BATCH: "v1/admin/settings/batch/update",
    UPDATE_SEMESTER_DATES: "v1/admin/settings/batch/update-semester-dates",
    SEMESTER_DATES: "v1/admin/settings/batch/semester-dates",
    COLLEGE_YEARS: "v1/admin/settings/batch/list-college-years",

    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",
    UPDATE_DEPARTMENT_COURSE:
      "v1/admin/settings/departments/update-dept-course",

    SAVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/save",
    LIST_SUBJECT_NATURE: "v1/admin/settings/subjectnature/list",
    REMOVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/delete",
    UPDATE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/update",

    SAVE_SUBJECT: "v1/admin/settings/subjects/save",
    LIST_SUBJECT: "v1/admin/settings/subjects/list",
    DELETE_SUBJECT: "v1/admin/settings/subjects/delete",
    UPDATE_SUBJECT: "v1/admin/settings/subjects/update",

    SAVE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/save",
    LIST_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/list",
    DELETE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/delete",
    UPDATE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/update",
    SAVE_STAFFSUBJECT: "v1/admin/settings/staffsubject/save",
    LIST_STAFFSUBJECT: "v1/admin/settings/staffsubject/list",
    DELETE_STAFFSUBJECT: "v1/admin/settings/staffsubject/delete",
    UPDATE_STAFFSUBJECT: "v1/admin/settings/staffsubject/update",

    SAVE_TIMETABLE: "v1/admin/settings/timetable/save-time-table",
    UPDATE_TIMETABLE: "v1/admin/settings/timetable/update-time-table",
    GET_TIMETABLE: "v1/admin/settings/timetable/get-timetable",
    REMOVE_TIMETABLE: "v1/admin/settings/timetable/remove",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",
  },
  FEE_CATEGORY: {
    SAVE_CATEGORY: "v1/admin/fees/category/save",
    LIST_CATEGORY: "v1/admin/fees/category/list",
    DELETE_CATEGORY: "v1/admin/fees/category/delete",
    UPDATE_CATEGORY: "v1/admin/fees/category/update",

    SAVE_FEE_GROUP: "v1/admin/fees/group/save",
    LIST_FEE_GROUP: "v1/admin/fees/group/list",
    DELETE_FEE_GROUP: "v1/admin/fees/group/delete",
    UPDATE_FEE_GROUP: "v1/admin/fees/group/update",
    UPDATE_FEE_GROUP_CATEGORY_LIST: "v1/admin/fees/group/update-list-id",

    SAVE_PAYMENT_METHOD: "v1/admin/fees/payment_method/save",
    LIST_PAYMENT_METHOD: "v1/admin/fees/payment_method/list",
    DELETE_PAYMENT_METHOD: "v1/admin/fees/payment_method/delete",
    UPDATE_PAYMENT_METHOD: "v1/admin/fees/payment_method/update",

    CATEGORY_LIST_WITH_STUDENT_COUNT:
      "v1/admin/fees/assign/category-with-student-count",
    SAVE_COURSE_WISE_FEE: "v1/admin/fees/assign/save-course-wise-fee",
    SAVE_STUDENT_WISE_FEE: "v1/admin/fees/assign/save-student-wise-fee",
    SAVE_STUDENT_WISE_FEE_V2: "v1/admin/fees/assign/save-student-wise-feev2",

    SAVE_COURSE_WISE_FEE_ADJUST: "v1/admin/fees/assign/save-course-wise-adjust",
    SAVE_STUDENT_WISE_FEE_ADJUST:
      "v1/admin/fees/assign/save-student-wise-adjust",
  },
  FEES: {
    BILL_NO: "v1/admin/fees/payment/bill-no",
    LIST_PAYMENT: "v1/admin/fees/payment/student-bills",
    SAVE_STUDENT_PAYMENT: "v1/admin/fees/payment/save-student-payment",
    LIST_STUDENT_PAYMENT: "v1/admin/fees/payment/list-bills",
    VIEW_STUDENT_BILL: "v1/admin/fees/payment/get-bill",
    DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-fee-record",
    BULK_DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-bulk-fee-record",
    UPDATE_PAID_PAYMENT_DATE: "v1/admin/fees/payment/update-bill-date",

    CHALLAN_BILL_NO: "v1/admin/fees/challan/bill-no",
    CHALLAN_LIST_PAYMENT: "v1/admin/fees/challan/student-bills",
    CHALLAN_SAVE_STUDENT_PAYMENT: "v1/admin/fees/challan/save-student-payment",
    CHALLAN_LIST_STUDENT_PAYMENT: "v1/admin/fees/challan/list-bills",
    CHALLAN_VIEW_STUDENT_BILL: "v1/admin/fees/challan/get-bill",
    CHALLAN_DELETE_ASSIGNED_FEE: "v1/admin/fees/challan/delete-fee-record",

    FEE_CATEGORY_REPORT: "v1/admin/fees/reports/category-reoprt",
    FEE_CATEGORY_SUMMARY: "v1/admin/fees/reports/category-summary",
    CLASS_WISE_PENDING: "v1/admin/fees/reports/class-wise-pending",
    CUSTOM_FEE_REPORT: "v1/admin/fees/reports/custom-fee-report",
    CUSTOM_FEE_CATEGORY_REPORT: "v1/admin/fees/reports/custom-category-reoprt",

    LIST_TEMPLATES: "v1/admin/fees/template/list-all",
    REMOVE_TEMPLATE: "v1/admin/fees/template/remove-data",
    SAVE_TEMPLATE: "v1/admin/fees/template/save-data",
    UPDATE_TEMPLATE: "v1/admin/fees/template/update-data",

    CANCEL_BILL: "v1/admin/fees/payment/cancel-bill",

    SAVE_BANK: "v1/admin/fees/bank/save",
    LIST_BANKS: "v1/admin/fees/bank/list",
    DELETE_BANK: "v1/admin/fees/bank/delete",
    UPDATE_BANK: "v1/admin/fees/bank/update",

    SAVE_FINE: "v1/admin/fees/fine/save",
    LIST_FINE_LOGS: "v1/admin/fees/fine/list",

    SAVE_HOSTEL_FEE_IMPORT: "v1/admin/hostel/fee/save-import",
  },
  RECEIPT_PAYMENTS: {
    SAVE_RECPAY: "v1/admin/fees/recpay/save",
    LIST_RECPAY: "v1/admin/fees/recpay/list-bills",
    GET_RECPAY_BILL: "v1/admin/fees/recpay/get-bill",
    CANCEL_RECPAY_BILL: "v1/admin/fees/recpay/cancel-bill",

    SAVE_RECPAY_CHALLAN: "v1/admin/fees/recchallan/save",
    LIST_RECPAY_CHALLAN: "v1/admin/fees/recchallan/list-bills",
    GET_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/get-bill",
    CANCEL_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/cancel-bill",
  },
  TRANSPORT: {
    SAVE_VEHICLE: "v1/admin/vehicle/transport/save-data",
    LIST_VEHICLES: "v1/admin/vehicle/transport/list-data",
    REMOVE_VEHICLE: "v1/admin/vehicle/transport/remove-data",
    UPDATE_VEHICLE: "v1/admin/vehicle/transport/update-data",

    SAVE_DESTINATION: "v1/admin/vehicle/destination/save-data",
    LIST_DESTINATION: "v1/admin/vehicle/destination/list-data",
    REMOVE_DESTINATION: "v1/admin/vehicle/destination/remove-data",
    UPDATE_DESTINATION: "v1/admin/vehicle/destination/update-data",
  },
  PAYROLL: {
    SAVE_LEAVE: "v1/admin/payroll.save_leave",
    LIST_EMPLOYEES_LEAVES: "v1/admin/payroll.list_data",
    UPDATE_EMPLOYEES_LEAVE: "v1/admin/payroll.update_data",
    REMOVE_EMPLOYEES_LEAVE: "v1/admin/payroll.remove_data",

    SAVE_PERMISSION: "v1/admin/payroll.save_permission",
    LIST_EMPLOYEES_PERMISSION: "v1/admin/payroll.list_data_permission",
    UPDATE_EMPLOYEES_PERMISSION: "v1/admin/payroll.update_permission",
    REMOVE_EMPLOYEES_PERMISSION: "v1/admin/payroll.remove_permission",

    LIST_BREAKUP_HEADS: "v1/admin/payroll.list_breakup_heads",
    SAVE_HEAD: "v1/admin/payroll.save_head",
    UPDATE_HEAD: "v1/admin/payroll.update_head",
    REMOVE_HEAD: "v1/admin/payroll.remove_head",
    GET_BREAK_HEAD: "v1/admin/payroll.get_breakhead",

    SAVE_GRADE: "v1/admin/payroll.save_data_setup",
    LIST_SETUP: "v1/admin/payroll.list_data_setup",
    UPDATE_SETUP: "v1/admin/payroll.update_data_setup",
    REMOVE_SETUP: "v1/admin/payroll.remove_data_setup",

    SAVE_SALARY: "v1/admin/payroll.save_salary",
    LIST_SALARY: "v1/admin/payroll.salary_list",
    LIST_SALARY_SETTINGS: "v1/admin/payroll.salary_settings_list",
    LIST_SALARY_DATA: "v1/admin/payroll.salary_list_data",

    LIST_EMPLOYEE_SALARY_DETAILS: "v1/admin/payroll.employee_salary_details",
    EMPLOYEE_SALARY_SETTINGS: "v1/admin/payroll.employee_salary_settings",

    SAVE_MONTH_SALARY: "v1/admin/payroll.save_employee_month_salary",
  },
  HR: {
    SAVE_GRADE: "v1/admin/hr.save_grade",
    LIST_GRADES: "v1/admin/hr.list_gardes",
    REMOVE_GRADE: "v1/admin/hr.remove_grade",
    UPDATE_GRADE: "v1/admin/hr.update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/hr.save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/hr.list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/hr.remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/hr.update_breakuphead",
    EMPLOYEE_NEW_CODE: "v1/admin/hr.employee_code",
    SAVE_EMPLOYEE: "v1/admin/hr.employee_save",
    LIST_EMPLOYEES: "v1/admin/hr.employees",
    REMOVE_EMPLOYEE: "v1/admin/hr.employee_remove",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hr.status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hr.employee_update",
    EMPLOYEE_BY_CODE: "v1/admin/hr.employee_by_code",
    SAVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_save",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hr.device_list",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_remove",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hr.device_update",
    LIST_LEAVE: "v1/admin/hr.leave_list",
    SAVE_HOLIDAY: "v1/admin/hr.holiday_save",
    LIST_HOLIDAYS: "v1/admin/hr.holiday_list",
    REMOVE_HOLIDAY: "v1/admin/hr.holiday_remove",
    SAVE_SUNDAYS: "v1/admin/hr.save_sundays",
    ATT_MONTH_LOG: "v1/admin/hr.att_month_log",
    SAVE_BRANCH: "v1/admin/hr.branch/save-data",
    LIST_BRANCHES: "v1/admin/hr.branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hr.branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hr.branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hr.department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hr.department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hr.department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hr.department/update-data",
    SAVE_DESIGNATION: "v1/admin/hr.designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hr.designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hr.designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hr.designation/update-data",

    SAVE_SHIFT: "v1/admin/hr.shift_save",
    LIST_SHIFT: "v1/admin/hr.shift_list",
    UPDATE_SHIFT: "v1/admin/hr.shift_update",
    REMOVE_SHIFT: "v1/admin/hr.shift_remove",
  },
  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  EVENTS: {
    SAVE_REPORTS: "v1/admin/events/save-reports",
    LIST_EVENTS_REPORT: "v1/admin/events/list",
    UPDATE_REPORTS: "v1/admin/events/update",
    REMOVE_REPORTS: "v1/admin/events/delete",
  },
  CIRCULAR: {
    SAVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/save-circular",
    LIST_COLLEGE_CIRCULAR: "v1/admin/circular/college/list",
    UPDATE_COLLEGE_CIRCULAR: "v1/admin/circular/college/update",
    REMOVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/delete",
    SAVE_OTHER_CIRCULAR: "v1/admin/circular/other/save-circular",
    LIST_OTHER_CIRCULAR: "v1/admin/circular/other/list",
    UPDATE_OTHER_CIRCULAR: "v1/admin/circular/other/update",
    REMOVE_OTHER_CIRCULAR: "v1/admin/circular/other/delete",
  },
  FACULTY: {
    LIST_ACTIVITIES: "v1/admin/faculty/list",
    SAVE_ACTIVITIES: "v1/admin/faculty/save",
    UPDATE_ACTIVITIES: "v1/admin/faculty/update",
    REMOVE_ACTIVITIES: "v1/admin/faculty/delete",
  },
  AWARD: {
    LIST_AWARDS: "v1/admin/awards/list",
    SAVE_AWARDS: "v1/admin/awards/save",
    UPDATE_AWARDS: "v1/admin/awards/update",
    REMOVE_AWARDS: "v1/admin/awards/delete",
  },
  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    UPDATE_PAGE: "v1/admin/cms/page/update",
    GET_EVENT_CATEGORY: "v1/admin/cms/page/get-event-category",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",
  },
  DEPT_CMS: {
    SAVE_SLIDER: "v1/admin/deptcms/slider/save",
    LIST_SLIDERS: "v1/admin/deptcms/slider/list",
    DELETE_SLIDER: "v1/admin/deptcms/slider/delete",

    SAVE_PAGE: "v1/admin/deptcms/page/save",
    GET_PAGE: "v1/admin/deptcms/page/get",

    SAVE_FILE: "v1/admin/deptcms/file/save",
    LIST_FILES: "v1/admin/deptcms/file/list",
    DELETE_FILE: "v1/admin/deptcms/file/remove",
    UPDATE_FILE: "v1/admin/deptcms/file/update",
  },
  NAAC: {
    SAVE_CRITERIA: "v1/admin/naac/save-criteria",
    LIST_CRITERIA: "v1/admin/naac/list-criteria",
    REMOVE_CRITERIA: "v1/admin/naac/remove-criteria",
    UPDATE_CRITERIA: "v1/admin/naac/update-criteria",

    SAVE_CRITERIA_GROUP: "v1/admin/naac/save-criteria-group",
    LIST_CRITERIA_GROUP: "v1/admin/naac/list-criteria-group",
    REMOVE_CRITERIA_GROUP: "v1/admin/naac/remove-criteria-group",
    UPDATE_CRITERIA_GROUP: "v1/admin/naac/update-criteria-group",

    SAVE_REPORT: "v1/admin/naac/save-report",
    LIST_REPORTS: "v1/admin/naac/list-reports",
    REMOVE_REPORT: "v1/admin/naac/remove-report",
    GET_REPORT: "v1/admin/naac/get-report",
    UPDATE_REPORT: "v1/admin/naac/update-report",
  },
  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",

    SAVE_DAY_ORDER: "v1/admin/util/attdayorder/save",
    LIST_DAY_ORDERS: "v1/admin/util/attdayorder/list",
    DELETE_DAY_ORDER: "v1/admin/util/attdayorder/delete",
    UPDATE_DAY_ORDER: "v1/admin/util/attdayorder/update",
    DAY_ORDER_BY_BATCH: "v1/admin/util/attdayorder/by-batch",

    SAVE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/save",
    LIST_ATTENDANCE_PERCENTAGES: "v1/admin/util/attpercentage/list",
    DELETE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/delete",
    UPDATE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/update",

    SAVE_SCHOLARSHIPS: "v1/admin/util/scholarship/save",
    LIST_SCHOLARSHIPS: "v1/admin/util/scholarship/list-all",
    DELETE_SCHOLARSHIPS: "v1/admin/util/scholarship/delete",
    UPDATE_SCHOLARSHIPS: "v1/admin/util/scholarship/update",
  },
  MOB: {
    CHECK_USERNAME: "mob/v1/login",
    VERIFY_LOGIN_OTP: "mob/v1/verify_otp",
    SET_LOGIN_PASSWORD: "mob/v1/set_password",
    MY_TIME_TABLE: "mob/v1/timetable/my-time-table",
    SEARCH_STUDENT: "mob/v1/students/search",
    SAVE_STUDENT_ATTENDANCE: "mob/v1/timetable/save",
    LIST_STUDENTS_ATTENDANCE: "mob/v1/timetable/list-attendance",
    CURRENT_SEM_DATE: "mob/v1/timetable/current-sem-date",
    LIST_LONG_ABSENTEE: "mob/v1/students/long-absentee",
  },

  //dmns sangam
  MEMBERS: {
    ADD_MEMBERS: "v1/admin/members/add",
    UPDATE_MEMBERS: "v1/admin/members/update",
    TOTAL_RECORDS_MEMBERS: "v1/admin/members/total-records",
    LIST_MEMBERS: "v1/admin/members/list",
    DELETE_MEMBERS: "v1/admin/members/delete",
    LIST_WARD: "v1/admin/members/list-ward",
    LIST_ELECTION_BOOTH: "v1/admin/members/list-booth",
    LIST_DISTRICT: "v1/admin/members/list-district",

    ADD_DISTRICT: "v1/admin/members/add-disctrict",
    UPDATE_DISTRICT: "v1/admin/members/update-disctrict",
    TOTAL_RECORDS_DISTRICT: "v1/admin/members/total-records-disctrict",
    // LIST_DISTRICT: "v1/admin/members/list",
    DELETE_DISTRICT: "v1/admin/members/delete-disctrict",

    ADD_WARD: "v1/admin/members/add-ward",
    UPDATE_WARD: "v1/admin/members/update-ward",
    TOTAL_RECORDS_WARD: "v1/admin/members/total-records-ward",
    // LIST_WARD: "v1/admin/members/list",
    DELETE_WARD: "v1/admin/members/delete-ward",

    ADD_BOOTH: "v1/admin/members/add-booth",
    UPDATE_BOOTH: "v1/admin/members/update-booth",
    TOTAL_RECORDS_BOOTH: "v1/admin/members/total-records-booth",
    // LIST_BOOTH: "v1/admin/members/list",
    DELETE_BOOTH: "v1/admin/members/delete-booth",

    ADD_BUCKET: "v1/admin/members/add-bucket",
    UPDATE_BUCKET: "v1/admin/members/update-bucket",
    TOTAL_RECORDS_BUCKET: "v1/admin/members/total-records-bucket",
    LIST_BUCKET: "v1/admin/members/list-bucket",
    DELETE_BUCKET: "v1/admin/members/delete-bucket",
    REMOVE_BUCKET: "v1/admin/members/remove-bucket",

    IMAGE_DOWNLOADER: "v1/admin/members/img_download",

    MEMBER_SMS: "v1/admin/members/member_sms",
    MEMBER_LIST_SMS: "v1/admin/members/member_list_sms",

    ADD_ISSUE: "v1/admin/members/add-issue",
    UPDATE_ISSUE: "v1/admin/members/update-issue",
    LIST_ISSUE: "v1/admin/members/list-issue",
    DELETE_ISSUE: "v1/admin/members/delete-issue",
    REMOVE_ISSUE: "v1/admin/members/remove-issue",
  },

  ASSETS: {
    SAVE_COMPANY: "v1/admin/assets/save-company",
    LIST_COMPANY: "v1/admin/assets/list-company",
    UPDATE_COMPANY: "v1/admin/assets/update-company",
    SAVE_ASSET: "v1/admin/assets/save-asset",
    LIST_ASSETS: "v1/admin/assets/list-assets",
    VIEW_ASSETS: "v1/admin/assets/view-assets",
    REMOVE_ASSET: "v1/admin/assets/remove-asset",
    REMOVE_COMPANY: "v1/admin/assets/remove-company",
    UPDATE_ASSETS: "v1/admin/assets/update-assets",
    SAVE_DISTRIBUTION: "v1/admin/assets/save-distribution",
    LIST_DISTRIBUTION: "v1/admin/assets/list-distribution",
    UPDATE_DISTRIBUTION: "v1/admin/assets/update-distribution",
    REMOVE_DISTRIBUTION: "v1/admin/assets/remove-distribution",
    SAVE_RETURN: "v1/admin/assets/save-return",
    LIST_RETURN: "v1/admin/assets/list-return",
    GET_RETURN: "v1/admin/assets/get-return",
    SAVE_SERVICE_RECORD: "v1/admin/assets/save-service-record",
    LIST_SERVICE_RECORD: "v1/admin/assets/list-service-record",
    UPDATE_SERVICE_RECORD: "v1/admin/assets/update-service-record",
  },
  VISITOR: {
    SAVE_VISITOR: "v1/admin/visitors/save-visitor",
    LIST_VISITORS: "v1/admin/visitors/list-visitors",
    UPDATE_VISITOR: "v1/admin/visitors/update-visitor",
    REMOVE_VISITOR: "v1/admin/visitors/remove-visitor",
  },
  LETTERS: {
    SAVE_LETTER: "v1/admin/letters/save-letter",
    LIST_LETTERS: "v1/admin/letters/list-letters",
    UPDATE_LETTER: "v1/admin/letters/update-letter",
    REMOVE_LETTER: "v1/admin/letters/remove-letter",
    SAVE_LETTER_EXTERNAL: "v1/admin/letters/save-letter-external",
    LIST_LETTERS_EXTERNAL: "v1/admin/letters/list-letters-external",
    UPDATE_LETTER_EXTERNAL: "v1/admin/letters/update-letter-external",
    REMOVE_LETTER_EXTERNAL: "v1/admin/letters/remove-letter-external",
  },
  DISCIPLINARY: {
    SAVE_DISCIPLINARY: "v1/admin/disciplinaryAction/save",
    LIST_DISCIPLINARY: "v1/admin/disciplinaryAction/list",
    DELETE_DISCIPLINARY: "v1/admin/disciplinaryAction/delete?id=",
    UPDATE_DISCIPLINARY: "v1/admin/disciplinaryAction/update",
  },
  AWARDSTUDENTS: {
    SAVE_AWARDSTUDENTS: "v1/admin/awardsStudents/save",
    LIST_AWARDSTUDENTS: "v1/admin/awardsStudents/list",
    DELETE_AWARDSTUDENTS: "v1/admin/awardsStudents/delete?id=",
    UPDATE_AWARDSTUDENTS: "v1/admin/awardsStudents/update",
  },
  APPLICATIONS: {
    TOTAL_RECORDS_APPLICATION: "v1/admin/application/total-records-application",
    LIST_APPLICATION: "v1/admin/application/list-application",
  },
};
