import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";
import PsContext from "../../context";
import { appTittle } from "../../utils";
import { VENDOR_LOGO } from "../../utils/data";
import LoaderSubmitButton from "../../utils/LoaderSubmitButton";
import { ServiceUrl } from "../../utils/serviceUrl";

const LoginV2Username = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const [redirect, setRedirect] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    axios.defaults.headers.common["Api-Token"] = ``;
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios.post(ServiceUrl.LOGIN_V2.CHECK, new FormData(form)).then((res) => {
      if (res["data"].status == "1") {
        axios.defaults.headers.common["Api-Token"] = `${res["data"].api}`;
        //setRedirect(res["data"].data);
        checkUsernameExist();
      } else {
        toast.error(res["data"].message || "Incorrect Username");
        setLoader(false);
      }
    });
  };

  const checkUsernameExist = () => {
    setLoader(true);
    const form = new FormData();
    form.append("username", username);
    form.append("api", "1");
    axios.post(ServiceUrl.LOGIN_V2.CHECK, form).then((res) => {
      if (res["data"].status == "1") {
        axios.defaults.headers.common["Api-Token"] = `${res["data"].api}`;
        //setRedirect(res["data"].data);
        setRedirect(true);
      } else {
        toast.error(res["data"].message || "Incorrect Username");
      }
      setLoader(false);
    });
  };

  if (redirect) {
    return (
      <Redirect to={{ pathname: `/login`, state: { username: username } }} />
    );
  }

  if (context.logged == "yes") {
    history.push("/app");
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{appTittle}</title>
      </Helmet>

      <div
        className="container d-flex justify-content-center"
        style={{ minHeight: "calc(100vh)" }}
      >
        <div className=" align-self-center">
          <Card className="shadow-sm py-4 px-4" style={{ minWidth: "350px" }}>
            <Card.Body>
              <div className="text-center mb-30">
                <img
                  src={VENDOR_LOGO}
                  className="bg-white rounded-3 mb-3"
                  style={{ width: "120px" }}
                />
              </div>
              <Form
                action=""
                method="post"
                noValidate
                validated={validated}
                autoComplete="off"
                onSubmit={handleFormSubmit}
              >
                <div>
                  <Row>
                    <Col md={12}>
                      <b>Sign in</b>
                      <br />
                      to access your ERP account
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="Username"
                        className="fw-bold"
                        autoComplete="nope"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        readOnly={loader}
                        autoFocus
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter your username
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <LoaderSubmitButton
                        type="submit"
                        loading={loader}
                        className="w-100"
                        text="Next"
                        size="md"
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default LoginV2Username;
